import React from "react";

import classes from "./TestimonialCard.module.css";

const TestimonialCard = ({ testimonial }) => {
  return (
    <div className={classes["testimonial-card-wrapper"]}>
      <div className={classes["testimonial-card-content"]}>
        {testimonial.text}
      </div>
      <h4 className={classes["testimonial-card-memberName"]}>
        {testimonial.name}
      </h4>
      <p className={classes["testimonial-card-teamName"]}>{testimonial.team}</p>
    </div>
  );
};

export default TestimonialCard;
