import React from "react";
import AliceCarousel from "react-alice-carousel";
import TestimonialCard from "../common/testimonialCard/TestimonialCard";

import classes from "./Testimonials.module.css";

const testimonialsArray = [
  {
    id: 1,
    name: "Anđela",
    team: "Team Puzzle",
    text: "“I have learned so much in 48 hours. The organization was on a top-notch level and all people were friendly and helpful”",
  },
  {
    id: 2,
    name: "Jure",
    team: "Team Fatal Error",
    text: "“The topic was very interesting, the food was delicious, and the people were amazing.”",
  },
  {
    id: 3,
    name: "Ivana",
    team: "Team Fordor",
    text: "“This was our first hackathon experience, ZenDev teamed us up, and in the end, we won 3rd place. It was an extraordinary event in every sense.”",
  },
];

const Testimonials = () => {
  const responsive = {
    0: { items: 1 },
    784: { items: 2 },
    1140: { items: 4 },
  };
  return (
    <div
      id="testimonials"
      className={` ${classes["testimonials-section"]} section-container heading-text`}
    >
      <h2>Testimonials</h2>
      <p className={classes["subheading-text"]}>
        Exciting, interesting, fun, valuable, and intensive - that's how our
        participants described the first-ever #HACKtheZEN.
      </p>
      <AliceCarousel
        infinite="true"
        disableButtonsControls
        responsive={responsive}
        controlsStrategy="responsive"
      >
        {testimonialsArray.map((testimonial) => (
          <TestimonialCard key={testimonial.id} testimonial={testimonial} />
        ))}
      </AliceCarousel>
    </div>
  );
};

export default Testimonials;
