import React from "react";
import GridGallery from "../common/gridGallery/GridGallery";

import classes from "./Gallery.module.css";
import gallery1 from "../../assets/Gallery1/gallery1.png";
import gallery2 from "../../assets/Gallery1/gallery2.png";
import gallery3 from "../../assets/Gallery1/gallery3.png";
import gallery4 from "../../assets/Gallery1/gallery4.png";
import gallery5 from "../../assets/Gallery1/gallery5.png";
import gallery6 from "../../assets/Gallery1/gallery6.png";
import Bgallery1 from "../../assets/Gallery2/Bgallery1.png";
import Bgallery2 from "../../assets/Gallery2/Bgallery2.png";
import Bgallery3 from "../../assets/Gallery2/Bgallery3.png";
import Bgallery4 from "../../assets/Gallery2/Bgallery4.png";
import Bgallery5 from "../../assets/Gallery2/Bgallery5.png";
import Bgallery6 from "../../assets/Gallery2/Bgallery6.png";

const Gallery = () => {
  return (
    <div
      id="gallery"
      className={` ${classes["gallery-section"]} section-container heading-text`}
    >
      <div className={classes.header}>
        <h2>Gallery</h2>
        <p className={classes["gallery-heading-text"]}>
          Hackathon is a rollercoaster of actions, emotions, and energy levels.
          <br />
          Take a glimpse at some of the most epic moments.
        </p>
      </div>
      <GridGallery
        img1={gallery1}
        img2={gallery2}
        img3={gallery3}
        img4={gallery4}
        img5={gallery5}
        img6={gallery6}
      />
      <GridGallery
        img1={Bgallery1}
        img2={Bgallery2}
        img3={Bgallery3}
        img4={Bgallery4}
        img5={Bgallery5}
        img6={Bgallery6}
      />
    </div>
  );
};

export default Gallery;
