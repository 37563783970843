import React from "react";

import Button from "../common/button/Button";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import Link from "react-scroll/modules/components/Link";

import classes from "./Partners.module.css";

import redbull from "../../assets/redbull.svg";
import rands from "../../assets/RandS.png";
import sync from "../../assets/Sync.png";
import ZDPartners1 from "../../assets/Partners/ZDPartners1.webp";
import ZDPartners2 from "../../assets/Partners/ZDPartners2.webp";
import ZDPartners3 from "../../assets/Partners/ZDPartners3.webp";
import ZDPartners4 from "../../assets/Partners/ZDPartners4.webp";
import ZDPartners5 from "../../assets/Partners/ZDPartners5.webp";
import ZDPartners6 from "../../assets/Partners/ZDPartners6.webp";

const Partners = () => {
  const responsive = {
    0: { items: 1 },
    542: { items: 2 },
    812: { items: 4 },
  };
  return (
    <div
      id="partners"
      className={` ${classes["partners-section"]} section-container heading-text`}
    >
      <div className={classes.header}>
        <h2>Partners</h2>
        <p>If you want to take part in the next HACKtheZEN, contact us!</p>
      </div>
      <AliceCarousel
        infinite="true"
        disableButtonsControls
        responsive={responsive}
        controlsStrategy="responsive"
      >
        <a
          href="https://www.redbull.com"
          className={classes["redbull-image"]}
          target="_blank"
          rel="noreferrer"
        >
          <img src={redbull} alt="Redbull" />
        </a>
        <a
          href="https://www.ris.ba/"
          target="_blank"
          className={classes["ris-image"]}
          rel="noreferrer"
        >
          <img src={rands} alt="Redbull" />
        </a>
        <a
          href="https://sync.ba/"
          target="_blank"
          className={classes["sync-image"]}
          rel="noreferrer"
        >
          <img src={sync} alt="Redbull" />
        </a>
      </AliceCarousel>
      <div className={classes["partners-main-grid"]}>
        <img
          src={ZDPartners1}
          alt="ZDPartneri 1"
          className={classes["partners-picture"]}
        />
        <img
          src={ZDPartners2}
          alt="ZDPartneri 2"
          className={classes["partners-picture"]}
        />
        <img
          src={ZDPartners3}
          alt="ZDPartneri 3"
          className={classes["partners-picture"]}
        />
        <img
          src={ZDPartners4}
          alt="ZDPartneri 4"
          className={classes["partners-picture"]}
        />
        <img
          src={ZDPartners5}
          alt="ZDPartneri 5"
          className={classes["partners-picture"]}
        />
        <img
          src={ZDPartners6}
          alt="ZDPartneri 6"
          className={classes["partners-picture"]}
        />
      </div>
      <h4>
      If you want to be a part of next HACKTheZEN feel free to contact us.
      </h4>
      <Link to="contact" spy={true} smooth={true} offset={-100} duration={500} style={{width:"100%"}}>
        <Button className={classes["contact-us"]}>Contact Us</Button>
      </Link>
    </div>
  );
};

export default Partners;
