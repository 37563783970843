import React from "react";

import classes from "./Hackathon.module.css";

import dotsLine from "../../assets/DotsLine.svg";

const Hackathon = () => {
  return (
    <div id="hackathon" className={classes["hackaton-section"]}>
      <div
        className={` ${classes["hackathon-container"]} background-section-container`}
      >
        <div className={` ${classes.header} heading-text`}>
          <h2>About HACKtheZEN22</h2>
          <p className={classes["description-text"]}>
            We designed HACKtheZEN as a selection process for this year's
            internship program. We wanted to meet talented individuals and see
            creative thinking and teamwork in action.
          </p>
        </div>
        <div className={classes["info-text"]}>
          <div className={classes["question-container"]}>
            <h3>Goal</h3>
            <div className={classes["answer-container"]}>
              <img src={dotsLine} alt="Connection" />
              <div className={classes["answer-text"]}>
                <h4>Connected talented students and ZenDev professionals</h4>
              </div>
            </div>
            <div className={classes["answer-container"]}>
              <img src={dotsLine} alt="Connection" />
              <div className={classes["answer-text"]}>
                <h4>Award best teams and individuals</h4>
              </div>
            </div>
            <div className={classes["answer-container"]}>
              <img src={dotsLine} alt="Connection" />
              <div className={classes["answer-text"]}>
                <h4>Empower IT community</h4>
              </div>
            </div>
          </div>
          <div className={classes["question-container"]}>
            <h3>Activities</h3>
            <div className={classes["answer-container"]}>
              <img src={dotsLine} alt="Connection" />
              <div className={classes["answer-text"]}>
                <h4>48 hours of coding</h4>
              </div>
            </div>
            <div className={classes["answer-container"]}>
              <img src={dotsLine} alt="Connection" />
              <div className={classes["answer-text"]}>
                <h4>Daily stand-ups and meetings with mentors</h4>
              </div>
            </div>
            <div className={classes["answer-container"]}>
              <img src={dotsLine} alt="Connection" />
              <div className={classes["answer-text"]}>
                <h4>After party</h4>
              </div>
            </div>
          </div>
          <div className={classes["question-container"]}>
            <h3>Results</h3>
            <div className={classes["answer-container"]}>
              <img src={dotsLine} alt="Connection" />
              <div className={classes["answer-text"]}>
                <h4>Amazing energy</h4>
              </div>
            </div>
            <div className={classes["answer-container"]}>
              <img src={dotsLine} alt="Connection" />
              <div className={classes["answer-text"]}>
                <h4>13 new zen participants</h4>
              </div>
            </div>
            <div className={classes["answer-container"]}>
              <img src={dotsLine} alt="Connection" />
              <div className={classes["answer-text"]}>
                <h4>New friendships</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hackathon;
