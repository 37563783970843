import React, { useRef } from "react";
import FormInput from "../common/formInput/FormInput";
import FormTextArea from "../common/formTextArea/FormTextArea";
import Button from "../common/button/Button";
import { ReactComponent as RightArrow } from "../../assets/Right.svg";
import emailjs from "@emailjs/browser";
import toast from "react-hot-toast";

import classes from "./ContactForm.module.css";

const ContactForm = () => {
  const usernameRef = useRef();
  const emailRef = useRef();
  const textRef = useRef();
  const form = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "default_service",
        "template_5t606a4",
        form.current,
        "cVz4F4V6Y1DjA5d0e"
      )
      .then(
        (result) => {
          toast.success(
            "Thank you for your message, we'll get back to you soon!"
          );
        },
        (error) => {
          toast.error("There has been an error, please try again later.");
        }
      );
    e.target.reset();
    usernameRef.current.clearFields();
    emailRef.current.clearFields();
    textRef.current.clearFields();
  };

  return (
    <div id="contact" className={` ${classes["contact-section"]} heading-text`}>
      <div className={classes["header"]}>
        <h2>Let's talk</h2>
        <p>
          Have a question or want to be a partner on the next HACKtheZEN,
          contact us!
        </p>
      </div>
      <form
        ref={form}
        className={classes["contact-form"]}
        onSubmit={handleSubmit}
      >
        <div
          className={classes["field-container"]}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <FormInput
            required
            type="text"
            name="username"
            errorMessage="Must include 3-30 characters and shouldn't include any special characters!"
            label="Your Name *"
            placeholder="Enter"
            pattern={"^[a-zA-Z ]{3,30}$"}
            ref={usernameRef}
          />
          <FormInput
            required
            name="email"
            type="email"
            errorMessage="Please provide a valid email address!"
            label="Your Email *"
            placeholder="Enter"
            ref={emailRef}
          />
        </div>
        <div className={classes["field-container"]}>
          <FormInput
            name="companyName"
            label="Company Name"
            placeholder="Enter"
            type="text"
          />
          <FormInput
            name="companyWeb"
            label="Company Website"
            placeholder="Enter"
            type="text"
          />
        </div>
        <FormTextArea
          required
          errorMessage="This is a required field"
          name="textArea"
          label="Message *"
          placeholder="Enter"
          ref={textRef}
        />
        <Button
          newStyle={{
            marginTop: "3rem",
            justifySelf: "flex-start",
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          Send A Message
          <RightArrow style={{ height: "30px" }} />
        </Button>
      </form>
    </div>
  );
};

export default ContactForm;
