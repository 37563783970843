import React from 'react'

import classes from "./RewardsAftermath.module.css";

import prize1 from "../../assets/Prize1.svg";
import prize2 from "../../assets/Prize2.svg";
import prize3 from "../../assets/Prize3.svg";
import FirstPlace from '../../assets/Winners/FirstPlace.webp'
import SecondPlace from '../../assets/Winners/SecondPlace.webp'
import ThirdPlace from '../../assets/Winners/ThirdPlace.webp'

const RewardsAftermath = () => {
  return (
    <div id="rewards" className={` ${classes["rewards-section"]} section-container`}>
    <div className="heading-text">
      <h2>Winners</h2>
      <p>Meet the best teams!</p>
    </div>
    <div className={classes["prizes-container"]}>
      <div className={classes["second-place"]}>
        <img src={prize2} className={classes["trophies"]} alt="2nd place trophy" />
        <div className={classes["white-background-circle"]} />
        <img src={SecondPlace} className={classes["image-trophies"]} alt="Second place" />
        <div className={classes["second-place-description"]}>
          <p className={classes["team-titles"]}>Team</p>
          <h3>Task Force 141</h3>
          <p className={classes["team-titles"]}>Members</p>
          <ul className={classes["second-place-memberList"]}>
            <li>Hana Fejzić</li>
            <li>Sanel Novalić</li>
            <li>Faris Hadžiomerivić</li>
            <li>Lamija Babović</li>
            <li>Anes Ljubuskić</li>
          </ul>
        </div>
      </div>
      <div className={classes["first-place"]}>
      <img src={prize1} className={classes["trophies"]} alt="1st place trophy" />
        <img src={FirstPlace} alt="First place" className={classes["image-trophies"]}/>
        <div className={classes["white-background-circle"]} />
        <div className={classes["second-place-description"]}>
          <p className={classes["team-titles"]}>Team</p>
          <h3>Uživaoci Alhamijado Književnosti</h3>
          <p className={classes["team-titles"]}>Members</p>
          <ul className={classes["second-place-memberList"]}>
            <li>Muhamed Omerović</li>
            <li>Hamza Kovačević</li>
            <li>Haris Kukuruzović</li>
            <li>Adnan Palavra</li>
            <li>Adnan Mahmić</li>
          </ul>
        </div>
      </div>
      <div className={classes["third-place"]}>
      <img src={prize3} className={classes["trophies"]} alt="3rd place trophy" />
        <img src={ThirdPlace} alt="Third place" className={classes["image-trophies"]}/>
        <div className={classes["white-background-circle"]} />
        <div className={classes["second-place-description"]}>
          <p className={classes["team-titles"]}>Team</p>
          <h3>Fordor</h3>
          <p className={classes["team-titles"]}>Members</p>
          <ul className={classes["second-place-memberList"]}>
            <li>Sanjin Pajić</li>
            <li>Amar Alikadić</li>
            <li>Ivana Škobić</li>
            <li>Ilhan Nezirić</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  )
}

export default RewardsAftermath