import "./App.css";
import AppHeader from "./layout/header/AppHeader";
import AppFooter from "./layout/footer/AppFooter";
import Article from "./components/Article/Article";
import Hackathon from "./components/Hackathon/Hackathon";
import Intership from "./components/Intership/Intership";
import Partners from "./components/Partners/Partners";
import RewardsAftermath from "./components/Rewards/RewardsAftermath";
import Testimonials from "./components/Testimonials/Testimonials";
import Gallery from "./components/Gallery/Gallery";
import ContactForm from "./components/ContactForm/ContactForm";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <div className="App">
      <Toaster/>
      <AppHeader />
      <div className="hackaton2022-content">
        <Article />
        <Hackathon />
        <RewardsAftermath />
        <Intership />
        <Testimonials />
        <Partners />
        <Gallery />
        <ContactForm />
      </div>
      <AppFooter />
    </div>
  );
}

export default App;
