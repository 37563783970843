import React from 'react'
import classes from './Button.module.css'


const Button = ({children,newStyle}) => {
  return (
    <button className={classes.button} style={{...newStyle}}>{children}</button>
  )
}

export default Button