import React from "react";
import classes from "./AppFooter.module.css";

const AppFooter = () => {
  return (
    <div className={classes.footer}>
      <div className={classes["copyright-container"]}>
        Copyright © 2022 ZenDev d.o.o. All Rights Reserved
      </div>
    </div>
  );
};

export default AppFooter;
