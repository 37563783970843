import React from "react";

import classes from "./Intership.module.css";
import Praktikanti1 from "../../assets/Praktikanti/Praktikanti1.webp";
import Praktikanti2 from "../../assets/Praktikanti/Praktikanti2.webp";
import Praktikanti3 from "../../assets/Praktikanti/Praktikanti3.webp";
import Praktikanti4 from "../../assets/Praktikanti/Praktikanti4.webp";
import Praktikanti5 from "../../assets/Praktikanti/Praktikanti5.webp";
import Praktikanti6 from "../../assets/Praktikanti/Praktikanti6.webp";

const Intership = () => {
  return (
    <div
      id="intership"
      className={` ${classes["intership-section-background"]} `}
    >
      <div className={`${classes["intership-section"]}  heading-text`}>
        <h2>Internship</h2>
        <p className={classes["internship-subTitle"]}>
          Top individuals received an invitation for ZenDev paid internship!
        </p>
        <div className={`${classes["internship-main-grid"]} `}>
          <div className={classes["grid-item"]}>
            <img
              src={Praktikanti1}
              alt="Praktikanti 1"
              className={classes["internship-picture"]}
            />
          </div>
          <div className={classes["grid-item"]}>
            <img
              src={Praktikanti2}
              alt="Praktikanti 2"
              className={classes["internship-picture"]}
            />
          </div>
          <div className={classes["grid-item"]}>
            <img
              src={Praktikanti3}
              alt="Praktikanti 3"
              className={classes["internship-picture"]}
            />
          </div>
          <div className={classes["grid-item"]}>
            <img
              src={Praktikanti4}
              alt="Praktikanti 4"
              className={classes["internship-picture"]}
            />
          </div>
          <div className={classes["grid-item"]}>
            <img
              src={Praktikanti5}
              alt="Praktikanti 5"
              className={classes["internship-picture"]}
            />
          </div>
          <div className={classes["grid-item"]}>
            <img
              src={Praktikanti6}
              alt="Praktikanti 6"
              className={classes["internship-picture"]}
            />
          </div>
        </div>
        <p className={classes["internship-participants"]}>
          Ivana, Selma, Ahmed, Tarik, Hamza, Mustafa, Arman, Haris, Amar
          Alikadić, Edis, Amar Ajanić, Adnan, Omar.
        </p>
        <h4>Welcome, we are glad to have you here!</h4>
      </div>
    </div>
  );
};

export default Intership;
