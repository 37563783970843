import React, { forwardRef, useImperativeHandle, useState } from "react";

import classes from "./FormInput.module.css";

const FormInput = forwardRef(
  (
    { placeholder, label, name, errorMessage, required, pattern, type },
    ref
  ) => {
    const [focused, setFocused] = useState(false);

    const handleFocused = (e) => {
      setFocused(true);
    };

    useImperativeHandle(ref, () => ({
      clearFields() {
        setFocused(false);
      },
    }));

    return (
      <div className={classes["form-input"]}>
        <label className={classes["form-input-label"]}>{label}</label>
        <input
          required={required}
          name={name}
          type={type}
          className={classes["form-input-field"]}
          placeholder={placeholder}
          onBlur={handleFocused}
          focused={focused.toString()}
          pattern={pattern}
        />
        <span>{errorMessage}</span>
      </div>
    );
  }
);

export default FormInput;
