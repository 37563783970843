import React, { forwardRef, useImperativeHandle, useState } from "react";

import classes from "./FormTextArea.module.css";

const FormTextArea = forwardRef(({ placeholder, label, name, required,errorMessage },ref) => {
  const [focused, setFocused] = useState(false);

  const handleFocused = (e) => {
    setFocused(true);
  };

  useImperativeHandle(ref,() => ({
    clearFields() {
      setFocused(false);
    }
  }))

  return (
    <div className={classes["form-textarea"]}>
      <label className={classes["form-textarea-label"]}>{label}</label>
      <textarea
        required={required}
        name={name}
        className={classes["form-textarea-field"]}
        placeholder={placeholder}
        onBlur={handleFocused}
        focused={focused.toString()}
      />
      {errorMessage && <span>{errorMessage}</span>}
    </div>
  );
});

export default FormTextArea;
