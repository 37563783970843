import React from "react";

import classes from "./Article.module.css";
import developer from "../../assets/Developers.jpg";
import time from "../../assets/Time.svg";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

import CountdownTimer from "../../layout/CountdownTimer/CountdownTimer";
import Link from "react-scroll/modules/components/Link";

const Article = () => {
  return (
    <div className={` ${classes["article-container"]} section-container`}>
      <div className={classes["text-container"]}>
        <h1>
          Make a{" "}
          <span className={classes["green-header"]}>positive impact</span> on{" "}
          <span className={classes["green-header"]}>people</span> and the{" "}
          <span className={classes["green-header"]}>planet.</span>
        </h1>
        <p>
        58 participants spent 48 hours developing an application for Žućo - movement for responsible behavior and a clean environment.
        </p>
        {/*<div className={classes["apply-container"]}>
          <Link
            className={` ${classes["apply-button"]} `}
            to="apply-form"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
          >
            Apply <FontAwesomeIcon icon={faArrowRight} />
          </Link>
  </div> */}
      </div>
      <div className={classes["image-container"]}>
        <img src={developer} alt="Developer" />
        {/* <div className={classes["countdown-container"]}>
          <div className={classes["clock-container"]}>
            <img src={time} alt="Clock" />
          </div>
          <div className={classes["countdown-closed"]}>
            <h3>Applications</h3>
            <CountdownTimer />
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Article;
