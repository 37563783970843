import React, { useState } from "react";
import classes from "./AppHeader.module.css";

import logo from "../../assets/MainLogo.svg";
import navbarToggler from "../../assets/NavbarToggler.svg";

import Link from "react-scroll/modules/components/Link";
import Button from "../../components/common/button/Button";

const AppHeader = () => {
  const [navbarToggle, setNavbarToggle] = useState(false);
  return (
    <div className={classes.header}>
      <div className={` ${classes["header-container"]} section-container`}>
        <div className={`${classes.logo} navbar-brand`}>
          <a href="#">
            <img src={logo} alt="Logo" />
          </a>
        </div>
        <div className={`${classes["nav-container"]} nav`}>
          <Link
            className="nav-link"
            to="hackathon"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
          >
            Hackathon
          </Link>
          <Link
            className="nav-link"
            to="rewards"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
          >
            Winners
          </Link>
          <Link
            className="nav-link"
            to="testimonials"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
          >
            Testimonials
          </Link>
          <Link
            className="nav-link"
            to="partners"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
          >
            Partners
          </Link>
          <Link
            className="nav-link"
            to="gallery"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
          >
            Gallery
          </Link>
          <Link
           to="contact"
           spy={true}
           smooth={true}
           offset={-100}
           duration={500}>
            <Button>Contact</Button>
           </Link>
        </div>
        {/* <div className={classes["apply-container"]}>
          <Link
            className={` ${classes["apply-button"]} nav-link `}
            to="apply-form"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
          >
            Apply
          </Link>
  </div> */}
        <div
          className={` ${classes["mobile-nav"]} ${classes["navbar"]} navbar`}
        >
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#collapsibleNavbar"
            onClick={() => setNavbarToggle(!navbarToggle)}
          >
            <img src={navbarToggler} alt="Menu" />
          </button>
        </div>
        {navbarToggle && (
          <div
            className={` ${classes["navbar-collapse"]} collapse navbar-collapse`}
            id="collapsibleNavbar"
          >
            <div className="bg-light p-3">
              <Link
                className="nav-link"
                to="hackathon"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                onClick={() => setNavbarToggle(!navbarToggle)}
              >
                Hackathon
              </Link>
              <Link
                className="nav-link"
                to="rewards"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                onClick={() => setNavbarToggle(!navbarToggle)}
              >
                Winners
              </Link>
              <Link
                className="nav-link"
                to="testimonials"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                onClick={() => setNavbarToggle(!navbarToggle)}
              >
                Testimonials
              </Link>
              <Link
                className="nav-link"
                to="partners"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                onClick={() => setNavbarToggle(!navbarToggle)}
              >
                Partners
              </Link>
              <Link
                className="nav-link"
                to="gallery"
                spy={true}
                smooth={true}
                offset={-100}
                duration={500}
                onClick={() => setNavbarToggle(!navbarToggle)}
              >
                Gallery
              </Link>
              {/* <div className={classes["apply-container"]}>
                <Link
                  className={` ${classes["apply-button-mobile"]} nav-link `}
                  to="apply-form"
                  spy={true}
                  smooth={true}
                  offset={-100}
                  duration={500}
                  onClick={() => setNavbarToggle(!navbarToggle)}
                >
                  Apply
                </Link>
        </div> */}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AppHeader;
