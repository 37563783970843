import React from 'react'

import classes from './GridGallery.module.css'

const GridGallery = ({img1,img2,img3,img4,img5,img6}) => {
  return (
    <div className={classes['main-grid']}>
        <img className={classes['grid-image']} src={img1} alt="Gallery 1"/>
        <img className={classes['grid-image']} src={img2} alt="Gallery 2"/>
        <img className={classes['grid-image']} src={img3} alt="Gallery 3"/>
        <img className={classes['grid-image']} src={img4} alt="Gallery 4"/>
        <img className={classes['grid-image']} src={img5} alt="Gallery 5"/>
        <img className={classes['grid-image']} src={img6} alt="Gallery 6"/>
    </div>
  )
}

export default GridGallery